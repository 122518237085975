<template>
  <div class="goods df">
    <div class="fn fw7 ckckc">
      <van-sidebar v-model="active">
        <van-sidebar-item
          v-for="(v, index) in classify"
          :key="index"
          :title="v.classification_name"
          @click="onChange(v.id)"
        />
      </van-sidebar>
    </div>

    <div class="footer df fdc">
      <!-- <div class="price df aic jcc fz16">
        配送费满 <span class="red"> 0.01减5</span>全店红包满<span class="red">59减20</span>、满<span class="red">89减30</span>
        …
      </div> -->
      <div class="fot df aic jcsb bsbb">
        <div class="count df aic jcc" style="margin-right: 75px">
          <div class="totalCount">
            <img
              src="../../assets/images/xg/shopcar1.png"
              class="mr20"
              @click="letShow"
            />
            <i v-if="isSelect != 0" class="font-size-16">{{ isSelect }}</i>
          </div>
          <div class="df fdc" style="width: 100px">
            <p>
              <b style="color: red" class="font-size-36 mr20"
                >¥{{ totalPrice }}</b
              >
              <span style="color: red" class="font-size-16" />
              <!-- <span class="font-size-16"
                  >优惠明细<van-icon name="arrow-up"
                /></span> -->
            </p>
          </div>
        </div>
        <div>
          <van-button
            :disabled="getMerchantList.length <= 0"
            round
            :type="type"
            class="font-size-36 fw7"
            @click="letShow"
            >去结算</van-button
          >
        </div>
      </div>

      <van-popup
        v-model:show="show"
        position="bottom"
        closeable
        :style="{ height: '50%' }"
      >
        <div class="p40 bsbb">
          <!-- <van-card ref="foodCard" v-for="subItem in detailList" :key="subItem.id" :price="subItem.trade_price"
            :title="subItem.trade_name" class="mb10">
            <template #thumb>
              <img :src="subItem.shop_home_img" class="img" style="width: 50px" />
            </template>
            <template #footer>
              <van-stepper min="0" max="9" theme="round" button-size="22" disable-input v-model="subItem.buy_quantity"
                default-value="0" />
            </template>
          </van-card> -->
          <div
            v-for="subItem in getMerchantList"
            :key="subItem.id"
            style="margin-top: 20px; width: 100%"
          >
            <van-row>
              <van-col span="24">
                <van-row>
                  <van-col span="5">
                    <img :src="subItem.shop_home_img" alt="" class="shop" />
                  </van-col>
                  <van-col span="19">
                    <van-row>
                      <van-col span="24">
                        <p class="texttil">
                          <span
                            style="
                              color: black;
                              border-radius: 10px;
                              font-weight: bold;
                            "
                            >【新鲜到货】</span
                          >{{ subItem.trade_name }}
                        </p>
                      </van-col>
                    </van-row>
                    <van-row style="margin-top: 10px">
                      <van-col span="24">
                        <p
                          style="
                            color: rgba(0, 0, 0, 0.5);
                            font-size: 12px;
                            margin-left: 8px;
                          "
                        >
                          月售702 <span>好评率100%</span>
                        </p>
                      </van-col>
                    </van-row>
                    <van-row style="margin-top: 10px">
                      <van-col span="8">
                        <p style="color: red" class="mony">
                          ￥{{ subItem.trade_price }}
                        </p>
                      </van-col>
                      <van-col span="16">
                        <div>
                          <van-field name="stepper">
                            <template #input>
                              <van-stepper
                                v-model="subItem.num"
                                min="0"
                                max="9"
                                theme="round"
                                button-size="22"
                                disable-input
                                default-value="0"
                                @change="handleGoods($event, subItem)"
                                @minus="handleMinus"
                              />
                            </template>
                          </van-field>
                        </div>
                      </van-col>
                    </van-row>
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </div>

          <!-- <div style="position: relative;width: 100%;height: 40px"></div> -->
          <div class="settle">
            <div class="money">
              <p class="spansty">
                ￥{{ totalPrice }}
                <!-- <span style="font-size: 12px;color: gray;">￥已优惠12.5</span> -->
              </p>
            </div>
            <van-button
              type="primary"
              class="buttenasd"
              :disabled="getMerchantList.length <= 0"
              @click="goPay"
              >去付款</van-button
            >
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  getGoodsList,
  createOrderReq,
  CommodityManagementClass,
  commodityManagement,
  CommodityManagementCClass,
} from "../../utils/api";
import { useStore } from "vuex";
import { Toast } from "vant";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { get } from "http";
export default {
  // props:['name'],
  data() {
    return {
      num: 0,
      1: 0,
      active: 0,
      goodID: -1,
      goodsList: [],
      currentList: [],
      page: 1,
      page_size: 10,
      orderMsg: {},
      onoff: true,
      disabled: false,
      type: "success",
      show: false,
      orderList: JSON.parse(localStorage.getItem("orderList")),
      order_info: JSON.parse(localStorage.getItem("orderList")),
      order_no: "",
      merchant_settlement_id: this.$route.query.id,
      values: 1,
      unitPprice: "50.88",
      unit: "50.88",
      id: "",
      classify: [],
      firstid: "",
      classification: "",
      show: false,
      names:this.$route.query.name,
    };
  },
  computed: {
    ...mapState("goods", ["merchantList"]),
    ...mapGetters("goods", [
      "getMerchantList",
      "goodsShopList",
      "getMerchantList",
    ]),
    isSelect: {
      get() {
        return this.goodsList.reduce((total, cur) => {
          return total + cur.buy_quantity;
        }, 0);
      },
    },
    totalPrice: {
      get() {
        let all = 0;
        all = this.getMerchantList.reduce((total, cur) => {
          return total + cur.trade_price * cur.num;
        }, 0);
        return all.toFixed(2);
      },
    },
    isDisabled: {
      get() {
        if (this.totalPrice == 0) {
          return (this.disabled = true);
          // this.type = "warning";
        } else {
          return (this.disabled = false);
          // this.type = "success";
        }
      },
    },
    detailList: {
      get() {
        const orderList = [];
        this.goodsList.forEach((v) => {
          if (v.buy_quantity > 0) {
            orderList.push(v);
          }
        });
        localStorage.setItem("orderList", JSON.stringify(orderList));
        // useStore().commit("updata", JSON.stringify(orderList))
        return orderList;
      },
    },
  },
  created() {
  //  this.id = this.$route.query.id;
    // this.getData();
    this.getclassify();
  },
  methods: {
    ...mapActions("goods", ["A_getMerchantList"]),
    ...mapMutations("goods", ["SET_GOODSSHOPLIST", "SET_RESET"]),
    // 监听商品修改事件
    handleGoods(num, item) {
      this.SET_GOODSSHOPLIST({ ...item, num });
    },
    // 弹出框数量减少事件,用于判断当前商品列表是否存在一个商品
    handleMinus() {
      console.log(this.getMerchantList.length);
      this.$nextTick(() => {
        if (this.getMerchantList.length <= 0) {
          this.show = false;
        }
      });
    },
    add() {
      this.unitPprice = this.unit * this.values;
    },
    async getData() {
      await getGoodsList({
        page: this.page,
        page_size: this.page_size,
        store_id: this.id,
      }).then((res) => {
        if (res.code === 200) {
          console.log(res, "www");
          this.goodsList = res.data.list;
        }
      });
    },
    // 调取支付接口
    goPay() {
      createOrderReq({
        commodity: this.getMerchantList.map((item) => ({
          id: item.id,
          quantity: item.num,
        })),
        payment_method: 10,
      }).then(async (res) => {
        console.log(res,'opop');
        if (res.code === 200) {
          this.order_no = res.data.order_no;
          await this.$router.push({
            name: "detail",
            query: {
              order_no: this.order_no,
              id: this.id,
              buy_actual_amount: res.data.buy_actual_amount,
              name: this.names,
            },
          });
        }
      });
    },

    goDetail(n) {
      console.log(this.$route.path,'555');
      this.handleGoods(1,n)
      this.$router.push({
        name: "goodDetail",
        query: {
          id: n.id,
          buy_actual_amount: n.trade_price,
          trade_name: n.trade_name,
          shop_home_img: n.shop_home_img,
          buy_quantity: 1,
          judge: this.$route.path,
        },
      });
    },
    letShow() {
      this.show = !this.show;
    },
    // 商品分类
    getclassify() {
      CommodityManagementCClass({}).then((res) => {
        if (res.code == 200) {
          this.classify = res.data;
          this.firstid = res.data.children[0].id;
          this.onChange(this.firstid);
        }
      });
    },
    // 根据分类id获取商品
    onChange(index) {
      // Toast({
      //   message: index,
      //   icon: 'success',
      //   className: 'noticeWidth'
      // });
      if (index === this.goodID) return false;
      this.goodID = index;
      this.A_getMerchantList({
        commodity_management_class_id: index,
        page: this.page,
        page_size: this.page_size,
        store_id: this.id,
      }).then((res) => {
        // this.currentList = this.getMerchantList(this.active);
        this.currentList = res;
        // this.goodsList = res;
        // this.classification = res;
      });

      // commodityManagement({
      //   commodity_management_class_id: index,
      //   page: this.page,
      //   page_size: this.page_size,
      //   store_id: this.id
      // }).then((res) => {
      //   console.log(res, 'qqq')
      //   this.goodsList = res.data.list
      //   this.classification = res.data.list
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
// padding: 20px;
.chebok {
  padding: 20px;
}

.shop {
  width: 130px;
  height: 130px;
}

.texttil {
  font-size: 26px;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.settle {
  width: 90%;
  height: 100px;
  border-radius: 56.5px;
  position: fixed;
  bottom: 50px;
  background-color: rgba(18, 18, 18, 1);

  .money {
    margin-left: 20px;
    line-height: 100px;
  }

  .spansty {
    font-size: 36px;
    color: white;
  }

  .buttenasd {
    position: absolute;
    border: none;
    top: 0px;
    right: 0px;
    width: 30%;
    height: 100px;
    font-weight: bold;
    font-size: 36px;
    border-radius: 0px 50px 50px 0px;
    background: rgba(55, 159, 0, 1);
  }
}

.mony {
  font-size: 36px;
  font-weight: bold;
}

.btns {
  padding: 10px;
}

.goods {
  font-family: "pingfang";
}

.red {
  color: red;
}

.tablist {
  .tab {
    height: 100px;
  }
}

.van-sidebar {
  width: 180px;
  text-align: center;

  .van-sidebar-item {
    height: 100px;
    text-align: center;
    line-height: 80px;
  }
}

.goodslist {
  position: relative;

  > img {
    width: 528.81px;
    height: 157px;
    border-radius: 10px;
  }

  > div {
    position: relative;

    .dertail {
      position: absolute;
      top: -50px;
      left: 0;

      .hot,
      .discount {
        width: 95px;
        height: 48px;
        border-radius: 10px;
        border: none;
        margin-right: 10px;
        font-size: 32px;
      }

      .hot {
        background: #abe0b3;
        color: green;
        font-size: 32px;
      }

      .discount {
        background: #f2f2f2;
        color: #000;
      }
    }

    .updown {
      position: absolute;
      top: 60px;
      left: 0;
    }

    .slot {
      font-size: 28px;
      width: 527px;
      height: 39px;
      border-radius: 8px;
      background: rgba(242, 242, 242, 1);
      position: absolute;
      top: 100px;
      left: 0;
    }
  }

  .list {
    .item {
      width: 524px;
      height: 189px;
      font-size: 24px;

      img {
        width: 186px;
      }

      .redborder {
        border: 1px solid red;
        padding: 3px;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        color: red;
      }

      .pay {
        span {
          padding: 5px;
          border-radius: 10px;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          display: inline-block;
          background: linear-gradient(
            90deg,
            rgba(151, 202, 114, 1) 0%,
            rgba(89, 194, 103, 1) 100%
          );

          img {
            z-index: 100;
            width: 30px;
          }
        }
      }
    }
  }

  .van-card {
    height: 180px;
    border-radius: 20px;
    margin-bottom: 20px;
    background: #fff;

    .van-image__error-icon {
      width: 80px;
    }

    .van-image__error-icon {
      font-size: 30px;
    }
  }
}

.footer {
  width: 100%;
  background: #fff;
  height: 190px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 10px;

  .price {
    width: 100%;
    height: 60px;
    background: #d6e6cc;
  }

  .fot {
    height: 138px;
    padding: 10px 30px;

    .count {
      .totalCount {
        position: relative;

        img {
          width: 82.25px;
          height: 82.25px;
          opacity: 1;
          margin-top: 30px;
        }

        i {
          display: inline-block;
          width: 30px;
          height: 30px;
          background: red;
          color: #fff;
          position: absolute;
          top: 20px;
          right: 15px;
          border-radius: 50%;
          text-align: center;
        }
      }
    }

    .van-button {
      width: 245px;
      height: 94px;
      opacity: 1;
      border-radius: 56.5px;
    }
  }
}

.van-popup {
  height: 400px !important;
  position: fixed !important;
  .btns {
    .confirm {
      width: 150px;
      position: absolute;
      bottom: 20px;
      right: 20px;
      height: 50px;
    }

    .cancel {
      width: 150px;
      position: absolute;
      bottom: 20px;
      left: 20px;
      height: 50px;
    }
  }
}

::v-deep .van-card__title {
  height: 100px;
  padding: 10px 20px;
}
</style>
<style lang="scss">
.van-field__control--custom {
  display: flex !important;
  justify-content: right !important;
  line-height: normal;
}

.van-stepper--round .van-stepper__plus {
  background: rgba(55, 159, 0, 1) !important;
}

.van-stepper--round .van-stepper__minus {
  border: 1px rgba(55, 159, 0, 1) solid !important;
}

.van-popup {
  // position: fixed !important;
}

.van-sidebar-item--select {
  font-weight: bold !important;
}
.ckckc{
  .van-sidebar-item__text{
    font-size: 32px;
  }
}
</style>
